import Flex from "app/components/Flex";
import Icon from "app/components/Icon";
import React from "react";
import { Text, Wrapper } from "./styles";

type EmptyStateProps = {
  icon: any;
  title: string;
  description: string;
};

export const EmptyState = ({ icon, title, description }: EmptyStateProps) => {
  return (
    <Wrapper>
      <Flex>
        <Flex mr="10.5px" mt="3px">
          <Icon as={icon} />
        </Flex>
        <Flex flexDirection="column">
          <Text fontWeight="700" fontSize="21px" mb="8px">
            {title}
          </Text>
          <Text fontWeight="400" fontSize="14px">
            {description}
          </Text>
        </Flex>
      </Flex>
    </Wrapper>
  );
};
