import Flex from "app/components/Flex";
import BaseText from "app/components/Text";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  width: 100%;
  height: 140px;
  background-color: ${({ theme }) => theme.colors.greys[0]};
  justify-content: center;
  align-items: center;
  border-radius: 2px;
`;

export const Text = styled(BaseText)`
  line-height: 110%;
  letter-spacing: 0.01em;
`;
